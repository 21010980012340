import React, { Component } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import { Link } from "gatsby"
import { FaGithub, FaReadme, FaTh, FaAlignJustify } from "react-icons/fa"
import Image from "../components/Images/Images"
import Title from "../components/Headings/Title"

const Items = [
  {
    category: "Biomedical",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
  {
    category: "Plants",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
  {
    category: "Flowers",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
  {
    category: "Automation",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
  {
    category: "Electronics",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
  {
    category: "Crypto",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
  {
    category: "Services",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
  {
    category: "Services",
    title: "Take control of your data with customizable tools and workflows",
    description: "Access biomedical data when you need it and how you need it",
    path: "/resources/services",
    image: "404",
  },
]

const getCategories = Items => {
  let categoryItems = Items.map(item => {
    return item.category
  })
  let uniqueCategories = new Set(categoryItems)
  let categories = Array.from(uniqueCategories)
  categories = ["All Projects", ...categories]
  return categories
}

class ProjectsPage extends Component {
  state = {
    togglelayout: true,
    AllProjectItems: Items,
    categories: getCategories(Items),
    selectedItem: getCategories(Items) && getCategories(Items)[0],
  }

  handleItems = category => {
    if (category === "All Projects") {
      this.setState({
        AllProjectItems: [...Items],
        selectedItem: category,
      })
    } else {
      this.setState({
        AllProjectItems: [
          ...Items.filter(item => {
            return item.category === category
          }),
        ],
        selectedItem: category,
      })
    }
  }

  render() {
    return (
      <Layout>
        <Seo title="Projects" />
        <section className="projects-section">
          <Title>Projects...</Title>
          <div className="container py-5">
            <div className="text-center mb-2">
              {this.state.categories.map((category, i) => {
                return (
                  <button
                    type="button"
                    key={i}
                    onClick={() => this.handleItems(category)}
                    className={
                      this.state.selectedItem === category
                        ? "btn m-1 bg-secondary text-light shadow"
                        : "btn btn-outline-primary m-1"
                    }
                  >
                    {category}
                  </button>
                )
              })}
            </div>

            <div className="text-center mb-4">
              <button
                className="btn bg-secondary text-light shadow"
                onClick={() =>
                  this.setState({ togglelayout: !this.state.togglelayout })
                }
              >
                {this.state.togglelayout ? <FaAlignJustify /> : <FaTh />}
              </button>
            </div>

            {this.state.togglelayout ? (
              <div className="row justify-content-center">
                {this.state.AllProjectItems.map((item, i) => {
                  return (
                    <div key={i} className="col-lg-4 col-md-6 col-sm-12">
                      <div
                        className="card shadow mb-4 border-primary"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div className="card-body">
                          <p
                            className="badge bg-primary text-light shadow me-1"
                            style={{ fontSize: "0.8em" }}
                          >
                            {item.category}
                          </p>
                          <Image
                            className="card-img-top shadow mb-4"
                            filename={`${item.image}.jpg`}
                            alt={item.title}
                            style={{ borderRadius: "4px", height: "180px" }}
                          />

                          <h4 className="card-title fw-bold">{item.title}</h4>
                          <p className="card-text">{item.description}</p>
                          <Link
                            to={item.path}
                            className="btn btn-primary btn-sm shadow me-1"
                          >
                            <FaGithub /> Github
                          </Link>
                          <Link
                            to={item.path}
                            className="btn btn-primary btn-sm shadow me-1"
                          >
                            <FaReadme /> Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="row justify-content-center">
                {this.state.AllProjectItems.map((item, i) => {
                  return (
                    <div key={i} className="col-lg-10 col-md-12 col-sm-12">
                      <div
                        className="card shadow rounded list-group-item list-group-item-action border-primary mb-2"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div className="d-flex w-100 justify-content-between align-items-center m-1">
                          <div>
                            <h5 className="text-primary fw-bold mb-3">
                              {item.title}
                            </h5>

                            <small className="text-muted">
                              <Link
                                to={item.path}
                                className="btn btn-primary btn-sm shadow me-1"
                              >
                                <FaGithub /> Github
                              </Link>
                              <Link
                                to={item.path}
                                className="btn btn-primary btn-sm shadow me-1"
                              >
                                <FaReadme /> Read More
                              </Link>
                            </small>
                          </div>
                          <div>
                            {item.image ? (
                              <Image
                                className="shadow m-1"
                                filename={`${item.image}.jpg`}
                                alt={item.image}
                                style={{
                                  width: "100px",
                                  height: "65px",
                                  borderRadius: "4px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "100px",
                                  height: "65px",
                                  borderRadius: "4px",
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </section>
      </Layout>
    )
  }
}

export default ProjectsPage
